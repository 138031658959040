import React, { Component } from "react";
import { ProfileCard } from "./components";

class App extends Component {
    render() {
        return (
            <div>
                <div className="max-w-4xl flex items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0">
                    <ProfileCard />
                    <div className="w-full lg:w-2/5">
                        <img
                            className="rounded-none lg:rounded-tr-lg lg:rounded-br-lg shadow-2xl hidden lg:block"
                            src={process.env.PUBLIC_URL + "/me25.jpg"}
                            alt="Paulius Piečius"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
