import React, { Component } from "react";
import { FiverrIcon, GitHubIcon, GlobeIcon, LinkedInIcon, WorkIcon } from "../icons";

class ProfileCard extends Component {
    width = 24;
    height = 24;

    handleMailTo() {
        window.location = "mailto:paulius_8@live.com?subject=Opportunity to work together";
    }

    render() {
        return (
            <div id="profile" className="w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl bg-white mx-6 lg:mx-0">
                <div className="p-4 md:p-12 text-center lg:text-left">
                    <div
                        className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center"
                        style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/me.jpg')` }}
                    />
                    <h1 className="text-3xl font-bold pt-8 lg:pt-0">Paulius Piečius</h1>
                    <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-red-700 opacity-25" />
                    <p className="pt-4 text-gray-600 flex items-center justify-center lg:justify-start">
                        <WorkIcon />
                        Web Developer
                    </p>
                    <p className="pt-2 text-gray-600 flex items-center justify-center lg:justify-start">
                        <GlobeIcon />
                        Netherlands
                    </p>
                    <p className="pt-8 text-sm">Web developer with 5+ years of experience in the field. Specializes in creating websites and other web-based software.</p>
                    <p className="pt-8 text-sm">Have any questions? Feel free to contact me!</p>
                    <div className="pt-12 pb-8">
                        <button onClick={this.handleMailTo} className="bg-red-700 hover:bg-red-900 text-white font-bold py-2 px-4 rounded-full">
                            Get In Touch
                        </button>
                    </div>
                    <div className="mt-6 pb-16 lg:pb-0 w-4/5 lg:w-full mx-auto flex flex-wrap items-center justify-around">
                        <a className="link" href="https://github.com/baku-hatsu">
                            <GitHubIcon width={this.width} height={this.height} />
                        </a>
                        <a className="link" href="https://www.fiverr.com/pauliuspiecius">
                            <FiverrIcon width={this.width} height={this.height} />
                        </a>
                        <a className="link" href="https://www.linkedin.com/in/pauliuspiecius">
                            <LinkedInIcon width={this.width} height={this.height} />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProfileCard;
